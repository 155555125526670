import React from "react"

import PaintBlock from "../paint_block"

import showAuthenticationModal from "../../lib/show_authentication_modal"

const UnauthenticatedPaintBlock = paint => {
  return (
    <PaintBlock
      quantity={0}
      setNeeded={() =>
        showAuthenticationModal("shopping_list", {
          paint_id: paint.id
        })
      }
      setQuantity={quantity =>
        showAuthenticationModal("inventory", {
          paint_id: paint.id,
          quantity
        })
      }
      {...paint}
    />
  )
}

export default UnauthenticatedPaintBlock
