import React from "react"
import { isEmpty } from "lodash"

import AutolinkedText from "../autolinked_text"
import RecipeItems from "./components/recipe_items"

const RecipeVideo = ({ videoId }) => (
  <div className="recipe-page__video">
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
)

const RecipeContent = ({ description, items, videoId }) => {
  return (
    <main aria-label="Recipe method">
      {!isEmpty(videoId) && <RecipeVideo videoId={videoId} />}
      {!isEmpty(description) && (
        <p className="recipe-page__description">
          <AutolinkedText text={description} />
        </p>
      )}

      {isEmpty(items) && <p>No steps have been added to this recipe.</p>}
      {!isEmpty(items) && <RecipeItems items={items} />}
    </main>
  )
}

export default RecipeContent
