import React from "react"

import StepContent from "./step_content"

const elements = {
  heading: "h2",
  paragraph: "p",
  step: "li"
}

class RecipeItem extends React.Component {
  render() {
    const { tokens, type, photo, photoSrc } = this.props
    const Element = elements[type]

    // TODO: refactor this using classnames
    const className = `recipe-item recipe-item--${type} ${
      photoSrc ? "recipe-item--has-photo" : ""
    }`

    return (
      <Element className={className}>
        {photo ? (
          <>
            <a
              href="#"
              className="recipe-item__photo"
              data-action="slideshow#showSlide"
              data-slide-id={photo.id}
            >
              <img src={photo.thumbSrc} alt="" />
            </a>

            <span
              className="recipe-item__content"
              data-slideshow-target="slide"
              data-slide-props={JSON.stringify(photo)}
            >
              <StepContent tokens={tokens} />
            </span>
          </>
        ) : (
          <span className="recipe-item__content">
            <StepContent tokens={tokens} />
          </span>
        )}
      </Element>
    )
  }
}

export default RecipeItem
