import React from "react"

const RangeHeading = ({ name, url, paintTypes, selectedPaints, isLink }) => (
  <h5 className="paint-list__range-heading">
    <span className="paint-list__label">{isLink ? <a href={url}>{name}</a> : name}</span>

    {paintTypes.length > 1 && <span className="paint-list__total">{selectedPaints}</span>}
  </h5>
)

export default RangeHeading
