import React from "react"
import { intersection } from "lodash"

import groupPaintsByType from "../../lib/group_paints_by_type"
import PaintBlock from "../paint_block"
import UnauthenticatedPaintBlock from "../paint_block/unauthenticated"
import PaintSwatch from "../paint_swatch"
import RangeHeading from "./components/range_heading"
import RecipeRequirements from "./components/recipe_requirements"
import TypeHeading from "./components/type_heading"

import showAuthenticationModal from "../../lib/show_authentication_modal"

const PaintList = ({
  paints,
  paintRanges,
  includeUserTools = true,
  isAuthenticated = false,
  ownedAndNeededPaints = {
    quantities: {},
    neededPaints: []
  }
}) => {
  const groupedPaints = groupPaintsByType(paintRanges, paints)
  const totalPaints = paints.length

  const ownedPaints = paints.reduce((obj, paint) => {
    obj[paint.id] = ownedAndNeededPaints.quantities[paint.id] || 0
    return obj
  }, {})
  const ownedPaintsCount = Object.values(ownedPaints).filter(
    quantity => quantity > 0
  ).length

  const neededPaints = intersection(
    ownedAndNeededPaints.neededPaints,
    Object.keys(ownedPaints)
  )

  const missingPaints = paints
    .filter(({ id }) => !ownedPaints[id] && !neededPaints.includes(id))
    .map(({ id }) => id)

  const addToShoppingList = isAuthenticated
    ? () => ownedAndNeededPaints.needAll(missingPaints)
    : () =>
        showAuthenticationModal("shopping_list", {
          paint_id: missingPaints
        })

  return (
    <aside aria-label="Paint list">
      {groupedPaints.map(paintRange => (
        <div key={paintRange.id} className="paint-list__range">
          <RangeHeading isLink={includeUserTools} {...paintRange} />

          {paintRange.paintTypes.map(paintType => (
            <div key={paintType.name} className="paint-list__type">
              <TypeHeading {...paintType} />

              <ul className="paint-list__paints">
                {paintType.paints.map(paint => (
                  <li key={paint.id} className="paint-list__paint">
                    {includeUserTools ? (
                      isAuthenticated ? (
                        <PaintBlock
                          isMini
                          {...ownedAndNeededPaints.forPaint(paint)}
                          {...paint}
                        />
                      ) : (
                        <UnauthenticatedPaintBlock {...paint} />
                      )
                    ) : (
                      <PaintSwatch {...paint} />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}

      {includeUserTools && (
        <RecipeRequirements
          totalPaints={totalPaints}
          ownedPaintsCount={ownedPaintsCount}
          missingPaints={missingPaints}
          neededPaints={neededPaints}
          addToShoppingList={addToShoppingList}
        />
      )}
    </aside>
  )
}

export default PaintList
