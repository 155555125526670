import React from "react"
import { isObject } from "lodash"

import AutolinkedText from "components/autolinked_text"
import PaintSwatch from "components/paint_swatch"

class StepContent extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        {this.props.tokens.map((token, key) => {
          if (isObject(token)) {
            return (
              <PaintSwatch
                key={key}
                name={token.name}
                swatchStyle={token.swatchStyle}
                inline={true}
              />
            )
          } else {
            return <AutolinkedText key={key} text={token} />
          }
        })}
      </React.Fragment>
    )
  }
}

export default StepContent
