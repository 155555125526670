import React from "react"
import ContentEditable from "react-contenteditable"

import PaintSwatch from "components/paint_swatch"

import CheckIcon from "../../../views/icons/check.svg"
import RemoveIcon from "../../../views/icons/remove.svg"
import ListAdd from "../../../views/icons/list_add.svg"
import ListAdded from "../../../views/icons/list_added.svg"

import "./styles.scss"

const PaintBlock = ({ quantity, needed, isMini, setQuantity, setNeeded, ...paint }) => {
  const isOwned = quantity > 0

  const classes = [
    "paint-block",
    isOwned ? "paint-block--owned" : "",
    isMini ? "paint-block--mini" : "",
    needed ? "paint-block--needed" : ""
  ]

  const onInventoryClick = () => {
    setQuantity(isOwned ? 0 : 1)
  }

  const onListClick = () => {
    const newNeeded = !needed

    setNeeded(newNeeded)
  }

  const onQuantityChange = event => {
    setQuantity(event.currentTarget.textContent)
  }

  const onQuantityFocus = event => {
    const selection = window.getSelection()
    const range = document.createRange()

    range.selectNodeContents(event.currentTarget)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  return (
    <div className={classes.join(" ")}>
      <div className="paint-block__inventory">
        <button
          className="paint-block__inventory__toggle"
          tabIndex="-1"
          onClick={onInventoryClick}
        >
          {isOwned ? <CheckIcon /> : <RemoveIcon />}
        </button>

        <ContentEditable
          className="paint-block__inventory__quantity"
          html={quantity.toString()}
          onChange={onQuantityChange}
          onFocus={onQuantityFocus}
        />
      </div>

      <div className="paint-block__content">
        <div className="paint-block__swatch">
          <PaintSwatch {...paint} />
        </div>

        <button className="paint-block__list__toggle" onClick={onListClick}>
          {needed ? <ListAdded /> : <ListAdd />}
        </button>
      </div>
    </div>
  )
}

export default PaintBlock
