import React from "react"
import { first } from "lodash"

import RecipeItem from "./recipe_item"

class RecipeItems extends React.PureComponent {
  render() {
    const { items } = this.props

    return (
      <>
        {items.map(itemGroup => {
          const recipeItem = first(itemGroup)

          if (recipeItem.type == "step") {
            let classNames = "recipe-items"
            if (itemGroup.length > 9) classNames = `${classNames} recipe-items--long`

            return (
              <ol key={itemGroup.map(item => item.id).join("-")} className={classNames}>
                {itemGroup.map(item => (
                  <RecipeItem key={item.id} {...item} />
                ))}
              </ol>
            )
          } else {
            return <RecipeItem key={recipeItem.id} {...recipeItem} />
          }
        })}
      </>
    )
  }
}

export default RecipeItems
