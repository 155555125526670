import fuzzy from "fuzzy"
import { orderBy } from "lodash"

const OPTIONS = {
  pre: "<b>",
  post: "</b>"
}

const filterByAttribute = (token, paints, attribute) =>
  fuzzy
    .filter(token, paints, { ...OPTIONS, extract: el => el[attribute] })
    .map(({ original, string, score }) => ({
      ...original,
      score,
      [attribute]: string
    }))

const filterByPaintRangeName = (token, paints) =>
  fuzzy
    .filter(
      token,
      paints.filter(paint => paint.paintRange),
      { extract: el => el.paintRange.name }
    )
    .map(({ original }) => original)

const filterByNameAndManufacturerCode = (token, paints) => {
  const paintsByName = filterByAttribute(token, paints, "name")
  const paintsByManufacturerCode = filterByAttribute(token, paints, "manufacturerCode")

  const combinedResults = [...paintsByName]
  paintsByManufacturerCode.forEach(paint => {
    const foundPaint = combinedResults.find(({ id }) => id === paint.id)

    if (foundPaint) {
      const { score, manufacturerCode } = paint

      Object.assign(foundPaint, {
        manufacturerCode,
        score: score + foundPaint.score
      })
    } else {
      combinedResults.push(paint)
    }
  })

  return combinedResults
}

export default ({ token, paints, limit }) => {
  const [paintToken, paintRangeToken] = token.split("/", 2).reverse()

  if (paintRangeToken) {
    paints = filterByPaintRangeName(paintRangeToken, paints)
  }

  const foundPaints = filterByNameAndManufacturerCode(paintToken, paints)

  let results

  if (foundPaints.length === 0) {
    results = paintRangeToken ? paints : filterByPaintRangeName(paintToken, paints)
  } else {
    results = orderBy(foundPaints, ["score", "id"], ["desc", "asc"])
  }

  if (limit) {
    return results.slice(0, limit)
  }

  return results
}
