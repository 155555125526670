import React from "react"
import Autolinker from "autolinker"

const NEWLINE_REGEX = /(\r\n|\r|\n)/g

// This is a rip off of: https://github.com/yosuke-furukawa/react-nl2br/blob/master/index.js
const nl2br = text => {
  if (typeof text !== "string") {
    return text
  }

  return text.split(NEWLINE_REGEX).map(function (line, index) {
    if (line.match(NEWLINE_REGEX)) {
      return <br key={index} />
    }

    return line
  })
}

const AutolinkedText = ({ text }) => {
  let elements = []
  let lastIndex = 0
  const parsedText = Autolinker.parse(text, {
    stripPrefix: { scheme: false, www: false }
  })

  parsedText.forEach(match => {
    if (match.getOffset() !== 0) {
      elements.push(nl2br(text.slice(lastIndex, match.getOffset())))
    }

    elements.push(
      <a
        key={elements.length}
        href={match.getAnchorHref()}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        {match.getAnchorText()}
      </a>
    )
    lastIndex = match.offset + match.getMatchedText().length
  })

  if (lastIndex < text.length) {
    elements.push(nl2br(text.slice(lastIndex)))
  }

  return elements
}

export default AutolinkedText
