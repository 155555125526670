import { sortBy } from "lodash"

export default (paintRanges, paints) =>
  sortBy(paintRanges, "name")
    .map(paintRange => {
      const paintsForRange = paints.filter(
        ({ paintRangeId }) => paintRangeId == paintRange.id
      )

      return {
        ...paintRange,
        selectedPaints: paintsForRange.length,
        paintTypes: paintRange.paintTypes
          .map(paintType => {
            const paintsForType = paintsForRange.filter(({ type }) => type == paintType)

            return {
              name: paintType,
              selectedPaints: paintsForType.length,
              paints: sortBy(paintsForType, ["manufacturerCode", "name"])
            }
          })
          .filter(({ selectedPaints }) => selectedPaints > 0)
      }
    })
    .filter(({ selectedPaints }) => selectedPaints > 0)
