import React from "react"

class PaintSwatch extends React.PureComponent {
  render() {
    let { name, swatchStyle, inline, manufacturerCode } = this.props

    return (
      <span className={`paint-swatch ${inline ? "paint-swatch--inline" : ""}`}>
        {swatchStyle && (
          <span className="paint-swatch__sample" style={swatchStyle}></span>
        )}
        {swatchStyle && name && " "}
        {!inline && manufacturerCode && (
          <span
            className="paint-swatch__manufacturer-code"
            dangerouslySetInnerHTML={{ __html: manufacturerCode }}
          />
        )}
        {!inline && manufacturerCode && " "}
        {name && (
          <span
            className="paint-swatch__name"
            dangerouslySetInnerHTML={{ __html: name }}
          />
        )}
      </span>
    )
  }
}

export default PaintSwatch
