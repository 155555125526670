import { ApolloClient, InMemoryCache } from "@apollo/client"

const cache = new InMemoryCache({
  typePolicies: {
    LogEntry: {
      fields: {
        collection(existingCollection, { canRead }) {
          return canRead(existingCollection) ? existingCollection : null
        }
      }
    }
  }
})

const client = new ApolloClient({ uri: "/graphql", cache })

export default client
