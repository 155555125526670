import queryString from "query-string"

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

const csrfToken = () => {
  const param = document.head.querySelector("[name=csrf-param]")
  const token = document.head.querySelector("[name=csrf-token]")

  if (param && token) {
    return { [param.content]: token.content }
  }
}

const urlWithQueryString = (url, data) =>
  data ? `${url}?${queryString.stringify(data, { arrayFormat: "bracket" })}` : url

export const get = (url, data) =>
  fetch(urlWithQueryString(url, data), {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    }
  }).then(handleErrors)

export const post = (url, body) =>
  fetch(url, {
    method: "POST",
    body: JSON.stringify({ ...csrfToken(), ...body }),
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    }
  }).then(handleErrors)

export const patch = (url, body) =>
  fetch(url, {
    method: "PATCH",
    body: JSON.stringify({ ...csrfToken(), ...body }),
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    }
  }).then(handleErrors)

export const del = (url, body) =>
  fetch(url, {
    method: "DELETE",
    body: JSON.stringify({ ...csrfToken(), ...body }),
    credentials: "same-origin",
    headers: {
      "content-type": "application/json"
    }
  }).then(handleErrors)
