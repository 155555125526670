import React from "react"

import CheckIcon from "../../../../views/icons/check.svg"

const Summary = ({ ownedPaintsCount, totalPaints }) => {
  let message

  if (totalPaints === ownedPaintsCount) {
    message = "You have all the paints you need for this recipe"
  } else if (ownedPaintsCount > 0) {
    message = (
      <React.Fragment>
        You have{" "}
        <strong>
          {ownedPaintsCount} of {totalPaints} paints
        </strong>{" "}
        needed for this recipe
      </React.Fragment>
    )
  } else {
    message = (
      <React.Fragment>
        You don&rsquo;t have any of the paints required for this recipe
      </React.Fragment>
    )
  }

  return <p className="recipe-requirements__summary">{message}</p>
}

const ShoppingListSummary = ({
  totalPaints,
  missingPaints,
  neededPaints,
  handleClick
}) => {
  if (neededPaints.length === 0 && missingPaints.length === 0) {
    return null
  }

  const onClick = event => {
    event.preventDefault()
    handleClick()
  }

  const message =
    totalPaints === missingPaints.length
      ? "Add all paints to your shopping list"
      : "Add missing paints to your shopping list"

  return (
    <div className="recipe-requirements__shopping-list">
      {neededPaints.length > 0 && (
        <p className="recipe-requirements__detail">
          <strong>
            {neededPaints.length} {neededPaints.length > 1 ? "paints" : "paint"}
          </strong>{" "}
          {neededPaints.length > 1 ? "are" : "is"} on your{" "}
          <a href="/shopping-list">shopping list</a>
        </p>
      )}

      {missingPaints.length !== 0 && (
        <p className="recipe-requirements__detail">
          <a href="#" onClick={onClick}>
            {message}
          </a>
        </p>
      )}
    </div>
  )
}

const RecipeRequirements = ({
  ownedPaintsCount,
  totalPaints,
  missingPaints,
  neededPaints,
  addToShoppingList
}) => (
  <div
    className={`recipe-requirements ${
      ownedPaintsCount === totalPaints ? "recipe-requirements--all" : ""
    }`}
  >
    {ownedPaintsCount === totalPaints && (
      <div className="recipe-requirements__icon">
        <CheckIcon />
      </div>
    )}

    <div className="recipe-requirements__content">
      <Summary totalPaints={totalPaints} ownedPaintsCount={ownedPaintsCount} />

      <ShoppingListSummary
        totalPaints={totalPaints}
        missingPaints={missingPaints}
        neededPaints={neededPaints}
        handleClick={addToShoppingList}
      />
    </div>
  </div>
)

export default React.memo(RecipeRequirements)
