import { DirectUpload } from "activestorage"
import { startsWith } from "lodash"

const performDirectImageUpload = ({ fileInput, url, beforeUpload, afterUpload }) => {
  const file = fileInput.files[0]

  if (!file) {
    return
  }

  if (!startsWith(file.type, "image/")) {
    alert("That file doesn't look like an image! Try another file.")

    fileInput.value = ""
    return
  }

  if (beforeUpload) {
    beforeUpload()
  }

  const upload = new DirectUpload(file, url)

  upload.create((error, attributes) => {
    if (error) {
      // TODO: proper error handling?
      alert("There was a problem uploading your image. Reload this page and try again.")
      return
    }

    afterUpload(attributes)
  })
}

export default performDirectImageUpload
