import React, { useState } from "react"

import Star from "../../../views/icons/star.svg"
import showAuthenticationModal from "../../lib/show_authentication_modal"
import { patch } from "lib/request"

import "./styles/index.scss"

const StarsButton = ({ isAuthenticated, isDisabled, recipeId, url, ...props }) => {
  const [isStarred, setIsStarred] = useState(props.isStarred)
  const [count, setCount] = useState(props.count)

  const className = [
    "stars-button",
    isStarred ? "stars-button--starred" : "",
    count == 0 ? "stars-button--none" : "",
    isDisabled ? "stars-button--disabled" : ""
  ].join(" ")

  let handleClick = null

  if (!isDisabled && isAuthenticated) {
    handleClick = () => {
      if (!isStarred) {
        setIsStarred(true)
        setCount(count + 1)
      } else {
        setIsStarred(false)
        setCount(count - 1)
      }

      patch(url)
    }
  } else if (!isDisabled) {
    handleClick = () => {
      showAuthenticationModal("star", {
        recipe_id: recipeId
      })
    }
  }

  return (
    <button className={className} onClick={handleClick} disabled={isDisabled}>
      <Star />
      <span className="stars-button__count">{count}</span>
    </button>
  )
}

export default StarsButton
