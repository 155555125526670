const showAuthenticationModal = (mode, params = {}) => {
  const event = new CustomEvent("authentication-modal.show", {
    detail: {
      mode,
      params
    }
  })

  window.dispatchEvent(event)
}

export default showAuthenticationModal
