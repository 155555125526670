import { compact, isEmpty, last, startCase, trim, uniq } from "lodash"

const PAINT_REGEX = /(:(?:\d+[a-z]|[a-z]+)[a-z\d/]*:)/i

const getPaintByToken = (token, paints) => {
  if (token.includes("/")) {
    return paints.find(paint => paint.fullIdentifier == token)
  } else {
    return paints.find(paint => paint.identifier == token)
  }
}

const convertTokenToObject = (token, paints, alternativePaintIds) => {
  if (!PAINT_REGEX.test(token)) {
    return token
  }

  token = trim(token, ":")
  let paint = getPaintByToken(token, paints)

  if (paint && alternativePaintIds[paint.id]) {
    paint = paints.find(({ id }) => id === alternativePaintIds[paint.id])
  }

  if (paint) {
    return paint
  } else {
    return { name: startCase(token) }
  }
}

const getTokenisedItem = (step, paints, alternativePaintIds = {}) => {
  const tokens = step.content
    .split(PAINT_REGEX)
    .map(token => convertTokenToObject(token, paints, alternativePaintIds))

  return {
    ...step,
    tokens,
    paintIds: uniq(compact(tokens.map(token => token.id))).sort()
  }
}

const groupAndTokeniseRecipeItems = (items, paints, alternativePaintIds = {}) =>
  items
    .filter(item => !isEmpty(item.content))
    .reduce((collection, item) => {
      const lastItemGroup = last(collection)
      const lastItem = last(lastItemGroup)

      if (
        !lastItemGroup ||
        (lastItem && lastItem.type != item.type) ||
        item.type == "heading" ||
        item.type == "paragraph"
      ) {
        collection.push([])
      }

      const lastIndex = collection.length - 1

      collection[lastIndex].push(getTokenisedItem(item, paints, alternativePaintIds))

      return collection
    }, [])

export default groupAndTokeniseRecipeItems
