import React from "react"

const TypeHeading = ({ name, selectedPaints }) => (
  <h6 className="paint-list__type-heading">
    <span className="paint-list__label">{name}</span>
    <span className="paint-list__total">{selectedPaints}</span>
  </h6>
)

export default TypeHeading
